import Swiper, { Autoplay, Scrollbar } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { log } from '~node_modules/sharp/lib/libvips.js';

if (document.getElementsByClassName('services-section__toggle-slider')[0]) {
  const servicesToggleSlidersArr = document.querySelectorAll(
    '.services-section__toggle-slider'
  );
  servicesToggleSlidersArr.forEach((sliderEl, sliderIdx) => {
    const servicesToggleSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Scrollbar],
      slidesPerView: 'auto',
      threshold: 10,
      slideToClickedSlide: true,
      scrollbar: {
        el: sliderEl.querySelector('.services-toggle-scrollbar'),
        draggable: true,
      },
    });
  });
}

if (document.getElementsByClassName('services-section__toggle-item')[0]) {
  const servicesToggleItemArr = document.querySelectorAll(
    '.services-section__toggle-item'
  );
  const servicesItemArr = document.querySelectorAll('.services-section__item');
  const servicesSectionLineBg = document.querySelector(
    '.services-section__line-bg'
  );
  const servicesSectionLineSpan = servicesSectionLineBg.querySelector('span');

  const leftOffset = servicesToggleItemArr[0].offsetLeft;

  servicesSectionLineSpan.style.width = `${servicesToggleItemArr[0].clientWidth}px`;
  servicesSectionLineSpan.style.marginLeft = `${leftOffset}px`;

  servicesToggleItemArr.forEach((toggleBtn, i) => {
    toggleBtn.addEventListener('click', () => {
      for (let j = 0; j < servicesToggleItemArr.length; j++) {
        servicesToggleItemArr[j].classList.remove('active');
      }
      for (let j = 0; j < servicesItemArr.length; j++) {
        servicesItemArr[j].classList.remove('active');
      }

      toggleBtn.classList.add('active');
      servicesItemArr[i].classList.add('active');

      const leftOffset = toggleBtn.offsetLeft;

      servicesSectionLineSpan.style.width = `${toggleBtn.clientWidth}px`;
      servicesSectionLineSpan.style.marginLeft = `${leftOffset}px`;
    });
  });
}
