import Swiper, { Autoplay } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('logos-section__slider')[0]) {
  const logosSlidersArr = document.querySelectorAll('.logos-section__slider');
  logosSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 5) {
      loop = true;
      loopedSlides = slides.length * 4;
    }

    const logosSwiper = new Swiper(sliderEl, {
      modules: [Autoplay],
      slidesPerView: 2,
      spaceBetween: 0,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      /*navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },*/
      breakpoints: {
        551: {
          slidesPerView: 2,
        },
        651: {
          slidesPerView: 2.4,
        },
        901: {
          slidesPerView: 3,
        },
        1025: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });

    swiperObserver(logosSwiper);
  });
}

import marquee from 'vanilla-marquee';

if (document.querySelector('.logos-section__row')) {
  const marqueeArray = [];

  const marqueeArr = document.querySelectorAll('.logos-section__row');
  marqueeArr.forEach((marqueeEl, i) => {
    const itemsArr = marqueeEl.querySelectorAll('.logos-section__item');
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });

    const finalItemsArr = marqueeEl.querySelectorAll('.logos-section__item');

    /*let speed = 1 / (finalItemsArr.length * finalItemsArr[0].clientHeight);

    if (window.innerWidth <= 1050 && window.innerWidth > 901) {
      speed = 2 / (finalItemsArr.length * finalItemsArr[0].clientHeight);
    }*/

    let direction = 'left';
    let pauseOnHover = true;

    if (window.innerWidth <= 1024) {
      pauseOnHover = false;
    }

    const mq = new marquee(marqueeEl, {
      delayBeforeStart: 0,
      direction: direction,
      pauseOnHover: pauseOnHover,
      duplicated: true,
      recalcResize: false,
      startVisible: true,
      gap: 0,
      speed: 20,
    });

    marqueeArray.push(mq);

    if (window.innerWidth <= 1024) {
      setTimeout(function () {
        const itemsArr = marqueeEl.querySelectorAll('.logos-section__item');
        itemsArr.forEach((item) => {
          item.addEventListener('click', (e) => {
            console.log('click');
            if (item.classList.contains('is-hover')) {
              item.classList.remove('is-hover');
              mq.resume();
            } else {
              const newArr = document.querySelectorAll('.logos-section__item');
              for (let j = 0; j < newArr.length; j++) {
                newArr[j].classList.remove('is-hover');
              }
              item.classList.add('is-hover');
              for (let j = 0; j < marqueeArray.length; j++) {
                marqueeArray[j].resume();
              }
              mq.pause();
            }
          });
        });
      }, 100);
    }

    /*finalItemsArr.forEach((item, i) => {
      item.addEventListener('click', (e) => {
        if (!item.classList.contains('is-active')) {
          item.classList.add('is-active');
        } else {
          item.classList.remove('is-active');
        }
      });
    });*/
  });

  document.addEventListener('click', function (event) {
    // Перевіряємо, чи клік був поза елементом marqueeEl
    if (!marqueeArr[0].parentElement.contains(event.target)) {
      const newArr = document.querySelectorAll('.logos-section__item');
      for (let j = 0; j < newArr.length; j++) {
        newArr[j].classList.remove('is-hover');
      }
      for (let j = 0; j < marqueeArray.length; j++) {
        marqueeArray[j].resume();
      }
    }
  });
}
