import { CountUp } from 'countup.js';

/**
 * CountUp Metamorfosi function
 * (just add .count-up class anywhere and setup unique option in data attributes)
 * **/
/**
 * CountUp Metamorfosi function
 * (just add .count-up class anywhere and setup unique option in data attributes)
 * **/
const counts = Array.from(document.getElementsByClassName('count-up'));
const countUpArr = [];
if (counts) {

  const defaultOptions = {
    // separator: "",
    enableScrollSpy: false,
    scrollSpyRunOnce: true,
    decimalPlaces: 0,
    duration: 3.2,
  };

  let idNumber = 1;

  counts.forEach((count) => {
    const id = `count-up-${idNumber}`,
      value = parseFloat(count.innerHTML);

    if (!isNaN(value)) {
      if (Number.isInteger(value)) {
        defaultOptions.decimalPlaces = 0;
      } else {
        const decimalCount = value.toString().split('.')[1] ? value.toString().split('.')[1].length : 0;

        if (decimalCount === 1) {
          defaultOptions.decimalPlaces = 1;
        } else if (decimalCount === 2) {
          defaultOptions.decimalPlaces = 2;
        } else {
          defaultOptions.decimalPlaces = 2;
        }
      }
    }


    let optionsFromDataAttr = { ...count.dataset };

    for (const key in optionsFromDataAttr) {
      if (optionsFromDataAttr[key] === '') {
        optionsFromDataAttr[key] = true;
      }
    }

    count.id = id;
    countUpArr.push(
      new CountUp(
        id,
        value,
        Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr)
      )
    );
    idNumber++;
  });
}

const scrollEvents = () => {
  const trueScrollTarget = document.getElementsByClassName('scroll-target')[0];

  if (trueScrollTarget) {
    const scrollTarget = document.querySelectorAll('.scroll-target');
    let progressArr = [];

    if (document.getElementsByClassName('progress-animation')[0]) {
      progressArr = document.querySelectorAll(
        '.progress-animation .stats-list__item'
      );
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, i) => {
          if (entry.isIntersecting) {
            if (
              progressArr.length !== 0 &&
              entry.target.classList.contains('stats-list__item')
            ) {
              const elem = entry.target;
              countUpArr.forEach((countUp) => {
                if (countUp.el.id === elem.querySelector('.count-up').id) {
                  countUp.start();
                  const progressElem = elem.querySelector('.progress-elem');
                  const suffixElem = elem.querySelectorAll('.count-elem span')[1];
                  let progress = Number(progressElem.dataset.progress);

                  if(suffixElem && suffixElem.textContent === 'years') {
                    function convertToPercentage(number) {
                      if (number < 0 || number > 5) {
                        return;
                      }

                      const percentage = (number / 5) * 100;
                      return percentage;
                    }

                    const number = progress;
                    const percentage = convertToPercentage(number);
                    
                    progress = percentage;
                  }

                  progressElem.classList.add('animation');
                  progressElem.style.setProperty(
                    '--widthBar',
                    `${Math.min(progress, 100)}%`
                  );
                }
              });
            }
          }
        });
      },
      {
        threshold: 0,
        rootMargin: '0% 0% -10% 0%',
      }
    );

    scrollTarget.forEach((target, index) => {
      observer.observe(target);
    });
  }
};

scrollEvents();
