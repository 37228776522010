import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import SimpleBar from "simplebar";

if (document.getElementsByClassName('industries-section__slider')[0]) {
  const industriesSlidersArr = document.querySelectorAll(
    '.industries-section__slider'
  );
  const industriesImagesSlidersArr = document.querySelectorAll(
    '.industries-section__images-slider'
  );
  industriesSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    const industriesSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 30,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      /*autoplay: {
        delay: 3200,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },*/
      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },
      breakpoints: {
        551: {
          spaceBetween: 30,
          slidesPerView: 1.5,
        },
        741: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
        821: {
          spaceBetween: 20,
          slidesPerView: 2.5,
        },
        951: {
          spaceBetween: 16,
          slidesPerView: 3,
        },
        1025: {
          spaceBetween: 20,
          slidesPerView: 3,
        },

        1330: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
      },
    });

    /*swiperObserver(industriesSwiper);*/

    const industriesImagesSwiper = new Swiper(
      industriesImagesSlidersArr[sliderIdx],
      {
        modules: [EffectFade],
        slidesPerView: 1,
        spaceBetween: 0,
        threshold: 10,
        effect: 'fade',
      }
    );

    const linksArr = sliderEl.querySelectorAll(
      '.industries-section__item-link'
    );
    const titlesArr = sliderEl.querySelectorAll(
      '.industries-section__item-title'
    );
    const buttonsArr = sliderEl.querySelectorAll(
      '.industries-section__item-buttons'
    );

    linksArr.forEach((link, index) => {
      link.addEventListener('mouseenter', () => {
        industriesImagesSwiper.slideTo(index);
      });
    });

    linksArr.forEach((link, i) => {
      const parent = link.parentElement.parentElement;
      const title = parent.querySelector('.industries-section__item-title');
      const btn = parent.querySelector('.industries-section__item-buttons');

      link.addEventListener('mouseenter', () => {
        linksArr.forEach((otherLink, j) => {
          if (i !== j) {
            otherLink.style.opacity = 0.5;
          }
        });
        for (let j = 0; j < titlesArr.length; j++) {
          titlesArr[j].style.opacity = 0.5;
        }
        for (let j = 0; j < buttonsArr.length; j++) {
          buttonsArr[j].style.opacity = 0.5;
        }
        title.style.opacity = 1;
        industriesImagesSlidersArr[sliderIdx].style.opacity = 1;
      });

      link.addEventListener('mouseleave', () => {
        linksArr.forEach((otherLink, j) => {
          if (i !== j) {
            otherLink.style.opacity = 1;
          }
        });
        for (let j = 0; j < titlesArr.length; j++) {
          titlesArr[j].style.opacity = 1;
        }
        for (let j = 0; j < buttonsArr.length; j++) {
          buttonsArr[j].style.opacity = 1;
        }
        industriesImagesSlidersArr[sliderIdx].style.opacity = 0;
      });
    });
  });
}

/*const btnShowHideArr = document.querySelectorAll('.btn-show-hide');
const showHideContentArr = document.querySelectorAll('.show-hide-content');

showHideContentArr.forEach((content, i) => {
  const items = content.querySelectorAll('.industries-section__item-link');
  const maxVisibleItems = 5;

  items.forEach((item, index) => {
    if (index > maxVisibleItems - 1) {
      item.classList.add('is-hidden');
    }
  });

  if(items.length > maxVisibleItems) {
    new SimpleBar(content);
  }

  if (items.length > maxVisibleItems) {
    const btnShowHide = btnShowHideArr[i];
    btnShowHide.classList.add('is-visible');

    btnShowHide.addEventListener('click', () => {
      btnShowHide.classList.toggle('active');
      items.forEach((item, index) => {
        if (index > maxVisibleItems - 1) {
          item.classList.toggle('is-hidden');
        }
      });
    });
  }
});*/
