if (document.querySelector('.know-industries-section__item')) {
  const aboutIndustriesItemsArr = document.querySelectorAll(
    '.know-industries-section__item'
  );
  const aboutIndustriesMainItemsArr = document.querySelectorAll(
    '.know-industries-section__main-item'
  );

  aboutIndustriesItemsArr.forEach((item, i) => {
    item.addEventListener('click', () => {
      for (let j = 0; j < aboutIndustriesItemsArr.length; j++) {
        aboutIndustriesItemsArr[j].classList.remove('active');
      }
      for (let j = 0; j < aboutIndustriesMainItemsArr.length; j++) {
        aboutIndustriesMainItemsArr[j].classList.remove('active');
      }

      item.classList.add('active');
      aboutIndustriesMainItemsArr[i].classList.add('active');
    });
  });
}
