import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('gallery-section__slider')[0]) {
  const gallerySlidersArr = document.querySelectorAll(
    '.gallery-section__slider'
  );
  gallerySlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    if (window.innerWidth < 768) {
      const gallerySwiper = new Swiper(sliderEl, {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 30,
        threshold: 10,
        /*loop: loop,
        loopedSlides: loopedSlides,*/
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
          nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
        },
        breakpoints: {
          551: {
            spaceBetween: 20,
            slidesPerView: 2,
          },
        },
      });

      swiperObserver(gallerySwiper);
    }
  });
}
