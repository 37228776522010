import Swiper, { Autoplay, Navigation } from 'swiper';

if (document.getElementsByClassName('other-teams-section__slider')[0]) {
  const otherTeamsSlidersArr = document.querySelectorAll(
    '.other-teams-section__slider'
  );
  otherTeamsSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    console.log(window.innerWidth);

    if (window.innerWidth <= 901) {
      const otherTeamsSwiper = new Swiper(sliderEl, {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 16,
        threshold: 10,
        /*loop: loop,
        loopedSlides: loopedSlides,*/
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
          nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
        },
        breakpoints: {
          551: {
            spaceBetween: 16,
            slidesPerView: 1.7,
          },
          651: {
            spaceBetween: 16,
            slidesPerView: 2.6,
          },
          751: {
            spaceBetween: 16,
            slidesPerView: 2.8,
          },
          901: {
            spaceBetween: 16,
            slidesPerView: 3,
          },
          1025: {
            spaceBetween: 20,
            slidesPerView: 3,
          },
          1200: {
            spaceBetween: 30,
            slidesPerView: 3,
          },
        },
      });
    }
  });
}
