import './utils/vh.utils.js';
import './utils/safari.utils.js';

import './components/animations.component.js';
import './utils/animate-on-view.utils.js';
import './utils/parallax-on-view.utills.js';
import './utils/accordion.utills.js';

import './components/header.component.js';
import './components/player.component.js';
// import './components/buttons.component.js';

import './components/uploadFile.component.js';
import './components/inputs.component.js';

/*Sliders*/
import './components/talent.component.js';
import './components/industries.component.js';
import './components/testimonials.component.js';
import './components/latest-jobs.component.js';
import './components/other-teams.component.js';
import './components/logos.component.js';
import './components/services.component.js';
import './components/gallery.component.js';

/*End sliders*/

import './components/expertise.component.js';
import './components/about-industries.component.js';
import './components/know-industries.component.js';
import './components/jobs-search.component.js';
import './components/other.component.js';
import './components/map.component.js';
import './components/first-jobs.component.js';

import './libs/countUp.lib.js';

var popupFD = document.querySelector('#popup');
if (popupFD) {
  /*var popupTitle = popupFD.querySelector(".popup__title");*/
  var popupBlock = popupFD.querySelector('#popup-block');

  window.pushPopup = function pushPopup(parentBlock, classHelper = '') {
    console.log(parentBlock);
    if (classHelper) {
      const cls = classHelper.split(' ');
      popupBlock.classList.add(...cls);
    }

    popupBlock.innerHTML = '';
    popupBlock.innerHTML = document.querySelector(parentBlock).innerHTML;

    popupFD.classList.add('active');
    scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    const scrollContentElement = document.querySelector('.popup__content');
    console.log(scrollContentElement);

    if (scrollContentElement) {
      const simpleBarInstance = new SimpleBar(scrollContentElement);
      simpleBarInstance.recalculate();
    }

    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    setTimeout(accordionInit, 300);
    console.log(accordionInit);

    if (popupFD.querySelector('.faq-accordion__item-text')) {
      const faqTextArr = popupFD.querySelectorAll('.faq-accordion__item-text');
      for (let i = 0; i < faqTextArr.length; i++) {
        new SimpleBar(faqTextArr[i]);
        setTimeout(function () {
          const scrollBlock = faqTextArr[i];
          const scrollsHorizontalArr = scrollBlock.querySelectorAll(
            '.simplebar-horizontal'
          );
          const scrollsVerticalArr = scrollBlock.querySelectorAll(
            '.simplebar-vertical'
          );
          if (scrollsHorizontalArr.length >= 1) {
            for (let i = 0; i < scrollsHorizontalArr.length; i++) {
              if (i !== 0) {
                scrollsHorizontalArr[i].remove();
                scrollsVerticalArr[i].remove();
              }
            }
          }
        }, 500);
      }
    }
  };
}

window.closePopup = function closePopup() {
  document.querySelector('#popup').classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
};
