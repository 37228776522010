/*import { mapJSON } from '~src/js/mapJSON.js';*/
import { autoPlacement, computePosition, flip, offset } from '@floating-ui/dom';

const map = document.getElementsByClassName('map-section__map')[0];

if (map) {
  const jsonData = mapJSON;

  window.mapLogic = function mapLogic(jsonData) {
    let openFlag = false;
    let activeStatesArr = [];
    try {
      /*const data = JSON.parse(jsonData);*/

      const mapTooltip = map.querySelector('.map-tooltip');

      jsonData.forEach((state, i) => {
        const stateId = state.id;
        const stateName = state.name;
        /*const stateVacanciesArr = state.vacancies;*/
        const stateVacanciesArr = '';

        const mapElement = map.querySelector(`#${stateId}`);
        const toggleMapElement = map.querySelector(`#state-${stateId}`);
        mapElement.classList.remove('is-hidden');
        toggleMapElement.classList.add('is-active');
        activeStatesArr.push(stateId);

        let positionEL = null;
        if (mapElement.children.length >= 2) {
          positionEL = mapElement.children[0];
          mapElement.classList.remove('map-logo');
          positionEL.classList.add('map-logo');
        } else {
          positionEL = mapElement;
        }

        function openTooltip() {
          openFlag = true;
          generatedTooltip(stateName, stateVacanciesArr);
          positionTooltip(positionEL);
        }

        mapElement.addEventListener('click', () => {
          openTooltip();
        });

        toggleMapElement.addEventListener('click', () => {
          openTooltip();
        });

        if (window.innerWidth >= 1025) {

          mapElement.addEventListener('mouseenter', () => {
            openTooltip();
          });

          toggleMapElement.addEventListener('mouseenter', () => {
            openTooltip();
          });

          toggleMapElement.addEventListener('mouseenter', () => {
            mapElement.classList.add('is-hovered');
          });

          toggleMapElement.addEventListener('mouseleave', () => {
            mapElement.classList.remove('is-hovered');
          });
        }
      });

      function positionTooltip(button) {
        computePosition(button, mapTooltip, {
          middleware: [offset(10), flip()],
          placement: 'right-end',
        }).then(({ x, y }) => {
          Object.assign(mapTooltip.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
        });
      }

      function generatedTooltip(stateName, stateVacanciesArr) {
        mapTooltip.classList.add('is-visible');
        const tooltipTitle = mapTooltip.querySelector(
          '.map-tooltip__title-name'
        );
        const tooltipList = mapTooltip.querySelector(
          '.map-tooltip__list-content'
        );

        tooltipTitle.innerHTML = '';
        tooltipList.innerHTML = '';

        tooltipTitle.textContent = `${stateName}`;

        /*if(stateVacanciesArr.length) {
          stateVacanciesArr.forEach(function (vacancy) {
            const stateSlug = vacancy.slug;

            const liElement = document.createElement('li');

            const spanElement = document.createElement('span');

            spanElement.textContent = vacancy.name;

            liElement.appendChild(spanElement);

            tooltipList.appendChild(liElement);

            /!*liElement.addEventListener('click', () => {
              const currentUrl = window.location.href;

              if (stateSlug) {
                const newUrl = currentUrl + '/job/' + stateSlug;
                window.location.href = newUrl;
              }
            });*!/
          });
        }*/

        if (openFlag === true) {
          document.addEventListener('click', (e) => {
            let target = e.target;

            const isIdInArray = activeStatesArr.some((state) =>
              target.id.includes(state)
            );

            const isTooltipClick = target.closest('.map-tooltip');

            if (!isTooltipClick && !isIdInArray) {
              mapTooltip.classList.remove('is-visible');
              openFlag = false;
            }
          });

          document.addEventListener('mousemove', (e) => {

            let target = e.target;

            const isIdInArray = activeStatesArr.some((state) =>
                target.id.includes(state)
            );

            const isTooltipClick = target.closest('.map-tooltip');

            if (!isTooltipClick && !isIdInArray) {
              mapTooltip.classList.remove('is-visible');
              openFlag = false;
            }
          });
        }
      }
    } catch (error) {
      console.error('Error parsing JSON data:', error.message);
      return {};
    }
  };

  mapLogic(jsonData);
}
