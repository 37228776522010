if (document.querySelector('.about-industries-section__item')) {
  const aboutIndustriesContentBg = document.querySelector('.about-industries-section__content-bg');
  const aboutIndustriesItemsArr = document.querySelectorAll(
    '.about-industries-section__item'
  );
  const aboutIndustriesMainItemsArr = document.querySelectorAll(
    '.about-industries-section__main-item'
  );

  aboutIndustriesItemsArr.forEach((item, i) => {
    item.addEventListener('click', () => {
      for (let j = 0; j < aboutIndustriesItemsArr.length; j++) {
        aboutIndustriesItemsArr[j].classList.remove('active');
      }
      for (let j = 0; j < aboutIndustriesMainItemsArr.length; j++) {
        aboutIndustriesMainItemsArr[j].classList.remove('active');
      }

      item.classList.add('active');
      aboutIndustriesMainItemsArr[i].classList.add('active');

      console.log(item.classList.contains('architecture-type'))

      if(item.classList.contains('architecture-type')) {
        aboutIndustriesContentBg.classList.remove('construction-type');
        aboutIndustriesContentBg.classList.remove('engineering-type');
        aboutIndustriesContentBg.classList.add('architecture-type');
      } else if(item.classList.contains('engineering-type')) {
        aboutIndustriesContentBg.classList.remove('architecture-type');
        aboutIndustriesContentBg.classList.remove('construction-type');
        aboutIndustriesContentBg.classList.add('engineering-type');
      } else {
        aboutIndustriesContentBg.classList.remove('architecture-type');
        aboutIndustriesContentBg.classList.remove('engineering-type');
        aboutIndustriesContentBg.classList.add('construction-type');
      }
    });
  });
}
