import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('testimonials-section__slider')[0]) {
  const testimonialsSlidersArr = document.querySelectorAll(
    '.testimonials-section__slider'
  );
  const testimonialsSliderButtonsArr = document.querySelectorAll(
    '.testimonials-slider-buttons'
  );
  testimonialsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const prevBtn =
      testimonialsSliderButtonsArr[sliderIdx].querySelector('.slider-btn.prev');
    const nextBtn =
      testimonialsSliderButtonsArr[sliderIdx].querySelector('.slider-btn.next');
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    /*let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    const testimonialsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3200,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: prevBtn,
        nextEl: nextBtn,
      },
      breakpoints: {
        551: {
          spaceBetween: 30,
          slidesPerView: 1,
        },

        651: {
          spaceBetween: 20,
          slidesPerView: 1.5,
        },

        951: {
          spaceBetween: 20,
          slidesPerView: 2,
        },

        1025: {
          spaceBetween: 24,
          slidesPerView: 2.2,
        },

        1200: {
          spaceBetween: 30,
          slidesPerView: 2.5,
        },
      },
    });

    swiperObserver(testimonialsSwiper);
  });
}
