/*Splitting();*/

import { log } from '~node_modules/sharp/lib/libvips.js';

if (document.querySelector('[data-splitting]')) {
  const splittingArr = document.querySelectorAll('[data-splitting]');
  splittingArr.forEach((item, i) => {
    const splitting = Splitting({
      target: item,
      by: 'lines',
    });
  });
}

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const knowledgeSection =
  document.getElementsByClassName('knowledge-section')[0];
if (knowledgeSection) {
  const triggerEl = knowledgeSection.querySelector(
    '.knowledge-section-trigger'
  );
  const bgEl = knowledgeSection.querySelector('.knowledge-section__bg-invert');
  const decorEl = knowledgeSection.querySelector(
    '.knowledge-section__bg-decor-mask'
  );

  let max = '90%';

  if (window.innerWidth <= 768) {
    max = '95%';
  }

  function knowledgeSecAnimation(triggerEl, animEl) {
    if (ScrollTrigger.isTouch !== 1) {
      gsap.fromTo(
        animEl,
        {
          maxHeight: 0,
        },
        {
          maxHeight: max,
          scrollTrigger: {
            trigger: triggerEl,
            end: 'top',
            scrub: true,
          },
        }
      );
    } else {
      animEl.parentElement.parentElement.setAttribute('data-view', '');
    }
  }

  knowledgeSecAnimation(triggerEl, bgEl);
  knowledgeSecAnimation(triggerEl, decorEl);
}

const lookingSection = document.getElementsByClassName('looking-section')[0];
if (lookingSection) {
  const triggerEl = lookingSection.querySelector('.looking-section-trigger');
  const bgEl = lookingSection.querySelector('.looking-section__bg-invert');
  const decorEl = lookingSection.querySelector(
    '.looking-section__bg-decor-mask'
  );

  let max = '95%';

  if (window.innerWidth <= 768) {
    max = '94%';
  }

  function lookingSecAnimation(triggerEl, animEl) {
    if (ScrollTrigger.isTouch !== 1) {
      gsap.fromTo(
        animEl,
        {
          maxHeight: 50,
        },
        {
          maxHeight: max,
          scrollTrigger: {
            trigger: triggerEl,
            end: 'top',
            scrub: true,
          },
        }
      );
    } else {
      animEl.parentElement.parentElement.setAttribute('data-view', '');
    }
  }

  lookingSecAnimation(triggerEl, bgEl);
  lookingSecAnimation(triggerEl, decorEl);
}

const joinSection = document.getElementsByClassName('join-section')[0];
if (joinSection) {
  const triggerEl = joinSection.querySelector('.join-section-trigger');
  const bgEl = joinSection.querySelector('.join-section__bg-invert');
  const decorEl = joinSection.querySelector('.join-section__bg-decor-mask');

  let max = '110%';

  if (window.innerWidth <= 768) {
    max = '96%';
  }

  function joinSecAnimation(triggerEl, animEl) {
    if (ScrollTrigger.isTouch !== 1) {
      gsap.fromTo(
        animEl,
        {
          maxHeight: 95,
        },
        {
          maxHeight: max,
          scrollTrigger: {
            trigger: triggerEl,
            end: 'top',
            scrub: true,
          },
        }
      );
    } else {
      animEl.parentElement.parentElement.setAttribute('data-view', '');
    }
  }

  joinSecAnimation(triggerEl, bgEl);
  joinSecAnimation(triggerEl, decorEl);
}

const bgElemsArr = document.querySelectorAll('[data-scroll-bg]');
if (bgElemsArr.length) {
  const triggersArr = document.querySelectorAll('[data-scroll-bg-trigger]');

  function bgMaxHeightAnimation(triggerEl, animEl, min, max) {
    if (ScrollTrigger.isTouch !== 1) {
      gsap.fromTo(
        animEl,
        {
          maxHeight: `${min}%`,
        },
        {
          maxHeight: `${max}%`,
          scrollTrigger: {
            trigger: triggerEl,
            end: 'top',
            scrub: true,
          },
        }
      );
    } else {
      animEl.parentElement.setAttribute('data-view', '');
    }
  }

  bgElemsArr.forEach((elem, i) => {
    if (elem.classList.contains('work-section__bg')) {
      bgMaxHeightAnimation(triggersArr[i], elem, 80, 100);
    } else if (elem.classList.contains('for-tomorrow-section__bg')) {
      bgMaxHeightAnimation(triggersArr[i], elem, 80, 100);
    } else if (elem.classList.contains('other-teams-section__bg')) {
      bgMaxHeightAnimation(triggersArr[i], elem, 90, 100);
    } else if (elem.classList.contains('know-industries-section__bg')) {
      bgMaxHeightAnimation(triggersArr[i], elem, 90, 100);
    } else {
      bgMaxHeightAnimation(triggersArr[i], elem, 65, 95);
    }
  });
}

let tl = gsap.timeline();
const scrollingText = document.querySelectorAll('[data-text-parallax]');
if (scrollingText.length >= 1) {
  let speed = 10;

  if (window.innerWidth <= 768) {
    speed = 15;
  }

  scrollingText.forEach((text, i) => {
    let val;
    if (!text.hasAttribute('data-text-parallax-revert')) {
      val = speed;
    } else {
      val = -speed;
    }

    if (ScrollTrigger.isTouch !== 1) {
      tl.to(text, {
        xPercent: val,
        scrollTrigger: {
          trigger: text,
          scrub: 0,
        },
      });
    } else {
      text.removeAttribute('data-text-parallax');
      if (text.hasAttribute('data-text-parallax-revert')) {
        text.removeAttribute('data-text-parallax-revert');
        text.setAttribute('data-view', 'fade-r');
      } else {
        text.setAttribute('data-view', 'fade-l');
      }
      text.parentElement.classList.add('optimize-animation');
    }
  });
}

const bigBox = document.querySelector('[data-box]');
if (bigBox) {
  const trigger = document.querySelector('.big-boxes-trigger');
  const boxesArr = bigBox.querySelectorAll('[data-box-img]');
  boxesArr.forEach((box, i) => {
    bigBoxesAnimation(trigger, boxesArr[0], -15, 15, 0, 0);
    bigBoxesAnimation(trigger, boxesArr[1], 15, 15, 0, 0);
    bigBoxesAnimation(trigger, boxesArr[2], -15, -15, 0, 0);
    bigBoxesAnimation(trigger, boxesArr[3], 15, -15, 0, 0);
  });

  function bigBoxesAnimation(triggerEl, animEl, minY, minX, maxY, maxX) {
    if (ScrollTrigger.isTouch !== 1) {
      gsap.fromTo(
        animEl,
        {
          translateY: `${minY}%`,
          translateX: `${minX}%`,
          opacity: 0,
        },
        {
          translateY: `${maxY}%`,
          translateX: `${maxX}%`,
          opacity: 1,
          scrollTrigger: {
            trigger: triggerEl,
            end: 'top',
            scrub: true,
          },
        }
      );
    } else {
      animEl.removeAttribute('data-scroll-bg');
      animEl.setAttribute('data-view', '');
    }
  }
}

/*
if (ScrollTrigger.isTouch !== 1) {}*/

function parallaxAnimation(e) {
  const elements = document.querySelectorAll('.decor-parallax');

  elements.forEach((elem) => {
    const speed = elem.getAttribute('data-speed');
    const rect = elem.getBoundingClientRect();

    if (rect.top < window.innerHeight && rect.bottom > 0) {
      const x = (window.innerWidth - e.pageX * speed) / 400;
      const y = (window.innerHeight - e.pageY * speed) / 400;

      gsap.to(elem, {
        x: x,
        y: y,
        duration: 0.5,
        ease: 'power2.out',
      });
    }
  });
}

if (window.innerWidth >= 1025 && document.querySelector('.decor-parallax')) {
  document.addEventListener('mousemove', parallaxAnimation);
}
