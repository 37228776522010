import Swiper, {Autoplay, EffectCreative, Navigation} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('first-section__btn-slider')[0]) {
  const firstJobsSlidersArr = document.querySelectorAll(
    '.first-section__btn-slider'
  );
  firstJobsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length > 1) {
      loop = true;
      loopedSlides = slides.length * 4;
    }

    const firstJobsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, EffectCreative],
      slidesPerView: 1,
      spaceBetween: 0,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      effect: 'creative',
      creativeEffect: {
        limitProgress: 3,
        prev: {
          translate: [-100, 0, 320],
          opacity: 0,
          effect: 'scale',
          scale: 0.5,
        },
        next: {
          /*translate: ['calc(100% - 20px)', 0, 0],*/
          translate: ['calc(100% + 20px)', 0, 0],
          opacity: 1,
          effect: 'scale',
          scale: 0.5,
        },
      },
/*      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },*/
    });

    swiperObserver(firstJobsSwiper);
  });
}
